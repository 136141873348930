<template>
  <div>
    <button class="m2 employee_download" @click="createExcel">Uitdraai maken</button>
    <table :class="`w-100 p-3 table--default table__border--${table_style}`">
      <thead :class="`table__head--${table_style}`">
        <tr>
          <th class="table__head--text">Foto</th>
          <th class="table__head--text">Item</th>
          <th class="table__head--text">Klant</th>
          <th v-if="franchise" class="table__head--text">Verkoper</th>
          <th class="table__head--text">Rdw Status</th>
          <th class="table__data--right table__head--text">Prijs</th>
          <th class="table__data--right table__head--text">Netto</th>
          <th class="table__data--right table__head--text">Betaald</th>
          <th class="table__data--right table__head--text">Datum</th>
          <th class="table__data--right table__head--text">Dagen V</th>
          <th class="table__data--right table__head--text">Lev</th>
          <th class="table__data--right table__head--text">Notities</th>
        </tr>
      </thead>
      <thead>
        <tr class="table__foot line-bottom">
          <th class="table__cell--default">Aantal: {{ table_data_total.aantal }}</th>
          <th class="table__cell--default"></th>
          <th class="table__cell--default"></th>
          <th class="table__cell--default"></th>
          <th class="table__cell--default table__data--right">€{{ table_data_total.prijs }}</th>
          <th class="table__cell--default table__data--right">€{{ table_data_total.netto }}</th>
          <th class="table__cell--default table__data--right">€{{ table_data_total.betaald }}</th>
          <th class="table__cell--default"></th>
          <th class="table__cell--default"></th>
          <th class="table__cell--default"></th>
          <th class="table__cell--default"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, key) in table_data" :key="key" :class="`table__row--color`">
          <td class="table__cell-image">
            <img :src="`https://apps.kleyn.com:1919/prod/public/data/mainimage/${item.itemno}/thumb`"
              class="table__picture--max-width" />
          </td>
          <td class="table__cell--default">
            <ImageHover :itemnummer="item.itemno" :bu="item.bu" />
            {{ item.soortnaam }}<br />
            {{ item.opbouwnaam }}<br />
            {{ item.merktype }}<br />
            {{ item.regjaar }}
          </td>
          <td class="table__cell--default">
            <router-link :to="{ name: 'klantpagina', params: { id: item.klant } }">{{ item.klant }}</router-link><br />
            {{ item.naam }}<br />
            {{ item.adres }}<br />
            {{ item.stad }}<br />
            {{ item.landnaam }}
          </td>
          <td v-if="franchise">{{ item.verkopernaam }}</td>
          <td class="table__cell--default">
            <div v-if="item.RdwStatus">
              Status: <strong>{{ item.RdwStatus }}</strong>
            </div>
            <hr v-if="item.RdwStatus || item.LaatsteAanpassing" />
            <div v-if="item.LaatsteAanpassing">
              Laatste aanpassing: <strong>{{ item.LaatsteAanpassing }}</strong>
            </div>
          </td>
          <td class="table__data--right table__cell--default">
            € {{ item.prijs }}
          </td>
          <td class="table__data--right table__cell--default">
            € {{ item.nettoprijs }}
          </td>
          <td class="table__data--right table__cell--default">
            € {{ item.Betaald }}
          </td>
          <td class="table__data--right table__cell--default">
            {{ item.datum }}
          </td>
          <td class="table__data--right table__cell--default">
            {{ item.dagenverkocht }}
          </td>
          <td class="table__data--right table__cell--default">
            {{ item.leveringsvoorwaarde }}
          </td>
          <td class="table__data--right table__cell--default">
            <b-button class="m-2" @click="getItemnoFromRow(item.itemno)"><i class="fas fa-sticky-note" /> + Nieuwe
              notitie
              toevoegen</b-button>
            <div v-for="(note, key) in item.notes" :key="key">
              <span v-b-toggle="`${note.Nummer}`" class="m-1"><i class="fas fa-caret-right" /> {{ note.Gebruiker }} -
                {{ note.datum }}</span>
              <b-collapse :id="note.Nummer" class="position-absolute">
                <b-card class="mr-3">
                  <div class="border-secondary bg-white">
                    {{ note.Aantekeningen }}
                  </div>
                </b-card>
              </b-collapse>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <b-modal id="create" ref="note_modal">
        <template #modal-header>
          <h2>Voeg notitie toe</h2>
        </template>
        <textarea class="form-control" id="note_field" rows="3" v-model.lazy="note_model"></textarea>
        <template #modal-footer>
          <div class="w-100">
            <b-button variant="primary" size="sm" class="float-right" @click="postNote">
              Toevoegen
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import moment from "moment";
import hasIndex from "@/functions/hasIndex";
import ImageHover from "@/components/ImageHover.vue";
import { msalInstance } from "vue-msal-browser";
import * as XLSX from "xlsx";

export default {
  props: {
    table_data: [Object, Array],
    table_data_total: [Object, Array],
    table_style: String,
    person_data: String,
    franchise: String
  },
  components: { ImageHover },
  data: () => ({
    note_model: "",
    add_note_itemno: null,
  }),
  methods: {
    createExcel() {
      let sales_orders = [];
      this.table_data.forEach((i) => {
        sales_orders.push({
          "Itemnummer": i.itemno,
          "Soort": i.soortnaam,
          "Opbouw": i.opbouwnaam,
          "Merk": i.merktype,
          "reg. jaar": i.regjaar,
          "Klant": i.klant,
          "Naam": i.naam,
          "Adres": i.adres,
          "Stad": i.stad,
          "Land": i.land,
          "Prijs": i.prijs,
          "Netto": i.nettoprijs,
          "Betaald": i.Betaald,
          "Datum": i.datum,
          "Dagen verkocht": i.dagenverkocht

        });
      });

      var telWS = XLSX.utils.json_to_sheet(sales_orders);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        telWS,
        `${this.person_data}_${this.moment().format("DD-MM-YYYY")}`
      );
      XLSX.writeFile(
        wb,
        `Verkooporders_${this.person_data}_${this.moment().format("DD-MM-YYYY")}.xlsx`
      );
    },

    getItemnoFromRow(itemno) {
      this.$refs["note_modal"].show();
      this.add_note_itemno = itemno;
    },
    postNote() {
      const posturl = "post-note";
      const data = {
        itemno: this.add_note_itemno,
        gebruiker: msalInstance.getAllAccounts()[0].idTokenClaims.name,
        datum: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
        aantekeningen: this.note_model,
        bu: this.table_style,
        person_data: this.person_data
      };
      request(posturl, "POST", data).then((values) => {
        let toastProps = {};
        if (hasIndex(values.message, "toegevoegd")) {
          toastProps = {
            message: values.message,
            type: "success",
          };
          this.$refs["note_modal"].hide();
        } else {
          toastProps = {
            message: values.error,
            type: "danger",
          };
          this.$refs["note_modal"].hide();
        }

        this.$store.dispatch("addNotification", toastProps);
      });
    },
  },
};
</script>
